import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { RichText, Date } from 'prismic-reactjs'
import { Link } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'

const Wrapper = styled.article`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4rem;
  background: ${props => props.theme.colors.black.lightest};
  border-radius: ${props => props.theme.borderRadius.default};
`

const Image = styled.div`
  position: relative;
  box-shadow: ${props => props.theme.shadow.feature.small.default};
  transition: ${props => props.theme.transitions.boom.transition};
  border-radius: ${props => props.theme.borderRadius.default};
  min-height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    border-radius: ${props => props.theme.borderRadius.default};
  }
  &:hover {
    box-shadow: ${props => props.theme.shadow.feature.small.hover};
    transform: translateY(-12px);
  }
  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${props => props.theme.borderRadius.default};
    > div {
      position: static !important;
    }
    > div > div {
      position: static !important;
    }
  }
  flex-basis: calc(99.9% * 2 / 5 - 1rem);
  max-width: calc(99.9% * 2 / 5 - 1rem);
  width: calc(99.9% * 2 / 5 - 1rem);
  @media (max-width: 800px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 500px) {
    min-height: 150px;
  }
`

const Information = styled.div`
  h1 {
    font-family: ${props => props.theme.fontFamily.heading};
    font-size: 2rem;
    margin-bottom: 1.25rem;
    display: inline-block;
    color: ${props => props.theme.colors.black.base};
    transition: all ${props => props.theme.transitions.default.duration};
    &:hover {
      color: ${props => props.theme.colors.primary.base};
    }
    @media (max-width: 800px) {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
  p {
    font-size: 1.2rem;
  }
  font-family: ${props => props.theme.fontFamily.body};
  flex-basis: calc(99.9% * 3 / 5 - 1rem);
  max-width: calc(99.9% * 3 / 5 - 1rem);
  width: calc(99.9% * 3 / 5 - 1rem);
  @media (max-width: 800px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`

const Statistics = styled.div`
  color: ${props => props.theme.colors.black.lighter};
  p {
    font-size: 1.7rem;
    @media (max-width: 800px) {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`

// A summary of the Blog Post
const ItemBlog = ({ post }) => {
  
  // Store and format the blog post's publication date
  let postDate = Date(post.date);
  postDate = postDate ? 
    new Intl.DateTimeFormat('de-DE', {
      month: 'short', 
      day: '2-digit', 
      year: 'numeric'
    }).format(postDate) :
    '';

  // Default title when post has no title set
  const defaultTitle = "Untitled"
  
  return (
    <Wrapper key={ post.id }>
      <Image 
        style={{
          backgroundImage: `url(${
            post.titleImage.url
          })`,
        }}>
        <Link to={ linkResolver(post._meta) }>
        </Link>
      </Image>
      <Information>
        <Link to={ linkResolver(post._meta) }>
          <h1>
            { RichText.asText(post.title).length !== 0 ? RichText.asText(post.title) : defaultTitle }
          </h1>
        </Link>
        <Statistics>
          <time>{ postDate }</time>
          <p>{RichText.asText(post.emojis)}</p>
        </Statistics>
        <p>{RichText.asText(post.intro)}</p>
      </Information>
    </Wrapper>
  );
}

export default ItemBlog

ItemBlog.propTypes = {
  path: PropTypes.string.isRequired,
  cover: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  excerpt: PropTypes.string.isRequired,
}